import React, { useState, useEffect } from "react"
import { Link, navigate } from "gatsby"
import { styled } from "twin.macro"
import { useAuth } from "../context/auth"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import Spinner from "react-bootstrap/Spinner"
import Alert from "react-bootstrap/Alert"

import agri from "../images/agri.png"
import logo from "../images/logo.png"

import Seo from "../components/seo"
import GlobalStyling from "../components/utils/GlobalStyling"

const LoginWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: inherit;
	width: inherit;
`

const LoginContainner = styled.div`
	display: flex;
	width: 750px;
`

const LoginImg = styled.div`
	flex-basis: 50%;
	height: 500px;
	background-image: url(${agri});
	background-repeat: no-repeat;
`

const LoginInputDiv = styled.div`
	height: 500px;
	flex-basis: 50%;
	width: 100%;
	background: white;
	padding: 0 40px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`
const LoginLogo = styled.div`
	background: url(${logo});
	background-repeat: no-repeat;
	height: 100px;
	width: 100%;
`

const ForgetPassword = styled.div`
	font-family: "Montserrat";
	margin-top: 25px;
`
const Anchor = styled(Link)`
	color: #697386;
	text-decoration: underline;
	font-weight: 600;
    font-size: 13px;
	&:hover {
		color: #343a40;
	}
`


/**boostrap Modified component */


const BForm = styled(Form)`
	width: 100%;
	font-family: "Montserrat";
`
const FormLabel = styled(Form.Label)`
	font-size: 14px;
    font-weight: 600;
	color: #697386;
`

const FormControl = styled(Form.Control)`
	font-size: 14px;
`

const BButton = styled(Button)`
	background: #0d8a72;
	width: 100%;
	font-size: 14px;
	border: none;
	&:hover {
		background: #0b6d5a;
	}
	&:focus {
		background: #0b6d5a;
		border: none;
	}
	&:disabled {
		background: #0b6d5a;
		border: none;
		cursor: default;
	}
	&:not(:disabled):not(.disabled):active {
		background: #0b6d5a;
		border: none;
	}
`
const BAlert = styled(Alert)`
	font-family: "Montserrat";
    font-size: 14px;
`

const AgriSpinner = styled(Spinner)`
	background: #0d8a72;
`

/** */

const IndexPage = () => {
	/** is submit button is loading  */
	const [isLoading, setIsLoading] = useState(false)
	/** is checking ==> loading auth status */
	const [checking, setChecking] = useState(true)
	const [error, setError] = useState("")
	const auth = useAuth()

	const handleSubmit = async (e) => {
		e.preventDefault();
		const {email, password} = e.target.elements

		/** validaition till now use only HTML validation */
		/**
		 * {state: { prevPath: window.location.pathname }
		 */

		try {
			setError("")
			setIsLoading(true)
			const res = await auth.login(email.value, password.value)
			console.log(res.user)
			auth.setCurrentUser(res.user)
			navigate("/dashboard", { state: { prevPath: window.location.pathname }, replace: true})
		} catch (error) {
			setError("les coordonnées saisi ne sont pas valide.")
		}
		setIsLoading(false)
	}


	useEffect(() => {
		if (auth.currentUser){
			navigate("/dashboard", { state: { prevPath: window.location.pathname }, replace: true})
		}
		setInterval(() => {
			setChecking(false)
		}, 1337)
	}, [])

	if (checking){
		return(
			<>
				<GlobalStyling/>
				<LoginWrapper><AgriSpinner animation="grow" /></LoginWrapper>
			</>
		)
	}

	return (
	<React.Fragment>
	<Seo title="Login" />
	<GlobalStyling />
	<LoginWrapper>
	{/* <AgriSpinner animation="grow" /> */}
		<LoginContainner>
			<LoginImg />
			<LoginInputDiv>
				<LoginLogo/>
				{error && <BAlert variant="danger">{error}</BAlert>}
				<BForm onSubmit={handleSubmit}>
					<Form.Group>
						<FormLabel htmlFor="email">Email</FormLabel>
						<FormControl type="email" placeholder="e-mail@name.com" id="email" required/>
					</Form.Group>

					<Form.Group>
						<FormLabel htmlFor="password">Mot de passe</FormLabel>
						<FormControl type="password" placeholder="Mot de passe" id="password" required/>
					</Form.Group>
					<BButton type="submit" disabled={isLoading}>
						{isLoading ? 'Loading…' : 'Se connecter'}
					</BButton>
				</BForm>
				<ForgetPassword>
				<Anchor to="/resetaccount">Mot de passe oublié ?</Anchor>
				</ForgetPassword>
			</LoginInputDiv>
		</LoginContainner>
	</LoginWrapper>
	</React.Fragment>
	)
}

export default IndexPage
